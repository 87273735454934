<template>
  <v-alert
      class="alert"
      v-model="cookie"
      color="primary"
      colored-border
      border="bottom"
      origin="bottom"
      width="70vw"
  >
    <h3 class="headline">
      Cookies
    </h3>
    <div>
      Wir nutzen Cookies zu unterschiedlichen Zwecken, unter anderem zur Analyse und für eine bessere Nutzererfahrung.
    </div>
    <div>
      Weitere Hinweise erhalten Sie in unserer <a href="/datenschutz">Datenschutzerklärung</a>.
    </div>
    <v-divider
        class="my-4 info"
        style="opacity: 0.22"
    ></v-divider>

    <v-row
        align="center"
        no-gutters
    >
      <v-col class="grow" cols="12" md="3">
        <v-checkbox v-model="essential" readonly label="Notwendige Cookies"></v-checkbox>
      </v-col>
      <v-col cols="12" md="3">
        <v-checkbox v-model="googleAnalytics" label="Google Analytics"></v-checkbox>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn
          outlined
          @click="close"
      >
        Weiter
      </v-btn>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "Cookie",
  data: () => ({
    cookie: false,
    essential: true,
    googleAnalytics: true
  }),
  methods: {
    checkForCookie(){
      console.log("CheckedForCookieAccept", localStorage.getItem(("cookie")))
      if (!localStorage.getItem(("cookie"))){
        this.cookie = true
      }
    },
    close(){
      localStorage.setItem("GoogleAnalytics",this.googleAnalytics)
      localStorage.setItem("cookie", "true")
      this.cookie = false
    }
  },
  mounted() {
    this.checkForCookie()
  }
}
</script>

<style scoped>
.alert {
  z-index: 999;
  position: fixed;
  bottom: 3vh;
  margin: 0 15vw 0 15vw;
}
</style>
