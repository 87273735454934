<template>
  <div>
    <v-app-bar
        v-if="route()"
        :flat="!isScrolling" fixed
               :style="isScrolling ? 'background-image: linear-gradient(131deg, rgba(170,200,224,1) 0%, rgba(170,181,224,0.989233193277311) 50%, rgba(182,170,224,1) 100%)' : ''"
               color="transparent" v-scroll="onScroll" elevate-on-scroll
               >
      <v-toolbar-title class="headline" v-if="route()">
        <!--
        <span class="hidden-xs-only">STAG </span><span class="hidden-md-and-down"> Dienstleistungsgesellschaft mbH</span>
        class="hidden-sm-and-up overflow-hidden"
        -->
        <v-avatar
            size="50"
            tile
        ><img
            :src="require('@/assets/Logo.png')"
            alt=""
        ></v-avatar>
        <span :class="isScrolling ? 'white--text hidden-md-and-down' : 'black--text hidden-md-and-down'" style="font-size: 12px">Ein Produkt der STAG Dienstleistungsgesellschaft mbH</span>
      </v-toolbar-title>
      <v-toolbar-items v-if="!route()">
        <v-btn
            text
            :class="isScrolling ? 'white--text' : ''"
            :light="!isScrolling"
            style="margin: 0;"
            to="/"
        >
          <span>Zurück</span>
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down" v-if="route()">
        <v-btn
            text
            v-for="buttons in button"
            :class="isScrolling ? 'white--text' : ''"
            :light="!isScrolling"
            :key="buttons.id"
            style="margin: 0"
            @click="goTo(buttons.ref)"
        >
          <span>{{ buttons.title }}</span>
        </v-btn>
        <v-btn
            :class="isScrolling ? 'white--text' : ''"
            :light="!isScrolling"
            text
            href="mailto:mpu-online@stag-service.de?subject=Kontaktanfrage%20von%20der%20Webseite&amp;body=Sehr%20geehrter%20Herr%20Weiland,%0D%0A%0D%0A"
        >
          <span class="mr-2">Kontakt</span>
        </v-btn>
      </v-toolbar-items>
      <v-divider class="hidden-sm-and-down" vertical inset v-if="route()" />
      <v-toolbar-items>
        <!-- Disabled Language Menu
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
                v-on="on"
                icon
            >
              <v-icon
              >
                mdi-web
              </v-icon>
            </v-btn>
          </template>
          <v-list v-model="$i18n.locale">
            <v-list-item
                :disabled="$i18n.locale === 'de'"
                @click="setLang('de')"
            >
              <v-list-item-title>Deutsch</v-list-item-title>
            </v-list-item>
            <v-list-item
                :disabled="$i18n.locale === 'en'"
                @click="setLang('en')"
            >
              <v-list-item-title>English</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        -->
        <v-btn icon @click="setTheme"  :class="isScrolling ? 'white--text' : ''"
               :light="!isScrolling">
          <v-icon>mdi-invert-colors</v-icon>
        </v-btn>
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" :class="isScrolling ? 'white--text' : ''"
                            :light="!isScrolling" @click.stop="toggleDrawer()"></v-app-bar-nav-icon>
      </v-toolbar-items>
    </v-app-bar>
    <v-app-bar
        v-if="!route()"
        fixed
               style="background-image: linear-gradient(131deg, rgba(170,200,224,1) 0%, rgba(170,181,224,0.989233193277311) 50%, rgba(182,170,224,1) 100%)"
               color="transparent" v-scroll="onScroll" elevate-on-scroll>
      <v-toolbar-title class="headline" v-if="route()">
        <!--
        <span class="hidden-xs-only">STAG </span><span class="hidden-md-and-down"> Dienstleistungsgesellschaft mbH</span>
        class="hidden-sm-and-up overflow-hidden"
        -->
        <v-avatar
            size="50"
            tile
        ><img
            :src="require('@/assets/Logo.png')"
            alt=""
        ></v-avatar>
        <span :class="isScrolling ? 'white--text' : 'black--text'" style="font-size: 12px">Ein Produkt der STAG Dienstleistungsgesellschaft mbH</span>
      </v-toolbar-title>
      <v-toolbar-items v-if="!route()">
        <v-btn
            text
            class="white--text"
            light
            style="margin: 0;"
            to="/"
        >
          <span>Zurück</span>
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-spacer class="hidden-sm-and-down"></v-spacer>
      <v-toolbar-items>
        <!-- Disabled Language Menu
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
                v-on="on"
                icon
            >
              <v-icon
              >
                mdi-web
              </v-icon>
            </v-btn>
          </template>
          <v-list v-model="$i18n.locale">
            <v-list-item
                :disabled="$i18n.locale === 'de'"
                @click="setLang('de')"
            >
              <v-list-item-title>Deutsch</v-list-item-title>
            </v-list-item>
            <v-list-item
                :disabled="$i18n.locale === 'en'"
                @click="setLang('en')"
            >
              <v-list-item-title>English</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        -->
        <v-btn icon @click="setTheme"  class="white--text"
               light>
          <v-icon>mdi-invert-colors</v-icon>
        </v-btn>
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" class="white--text"
                            light @click.stop="toggleDrawer()"></v-app-bar-nav-icon>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import {getFormURL} from "@/config/configBuilder";

export default {
  data: () => ({
    isScrolling: false,
    drawer: false,
    group: false,
    button: [
      {
        title: "Hilfe in 3 Schritten",
        ref: "#steps"
      },
      {
        title: "Zum Beratungspaket",
        ref: "#products"
      },
      {
        title: "Blogbeiträge",
        ref: "#interview"
      },
      {
        title: "Unser Team ",
        ref: "team"
      }
    ]
  }),
  methods: {
    toggleDrawer() {
      this.$emit('toggleDrawer')
    },
    onScroll() {
      this.isScrolling =
          (window.pageYOffset || document.documentElement.scrollTop || 0) > 100;
    },
    route: function () {
      return this.$route.fullPath === "/";
    },
    setTheme() {
      if (this.$vuetify.theme.dark) {
        localStorage.setItem("theme", "false")
        return (this.$vuetify.theme.dark = false);
      } else {
        localStorage.setItem("theme", "true")
        return (this.$vuetify.theme.dark = true);
      }
    },
    getTheme() {
      //dark on first
      if (!localStorage.getItem("theme")) {
        localStorage.setItem("theme", "true")
        return (this.$vuetify.theme.dark = true);
      }
      //remember selected theme
      this.$vuetify.theme.dark = JSON.parse(localStorage.getItem("theme"));
    },
    setLang(lang) {
      this.$vuetify.lang.current = lang;
      this.$i18n.locale = lang;
    },
    login() {
      window.location.href =
          getFormURL()
    },
    goTo(ref){
      if (ref[0] === "#"){
        this.$vuetify.goTo(ref)
      } else {
        this.$router.push(ref)
      }
    }
  },
  created() {
    this.getTheme();
  }
}

</script>
