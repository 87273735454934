import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAlert,{staticClass:"alert",attrs:{"color":"primary","colored-border":"","border":"bottom","origin":"bottom","width":"70vw"},model:{value:(_vm.cookie),callback:function ($$v) {_vm.cookie=$$v},expression:"cookie"}},[_c('h3',{staticClass:"headline"},[_vm._v(" Cookies ")]),_c('div',[_vm._v(" Wir nutzen Cookies zu unterschiedlichen Zwecken, unter anderem zur Analyse und für eine bessere Nutzererfahrung. ")]),_c('div',[_vm._v(" Weitere Hinweise erhalten Sie in unserer "),_c('a',{attrs:{"href":"/datenschutz"}},[_vm._v("Datenschutzerklärung")]),_vm._v(". ")]),_c(VDivider,{staticClass:"my-4 info",staticStyle:{"opacity":"0.22"}}),_c(VRow,{attrs:{"align":"center","no-gutters":""}},[_c(VCol,{staticClass:"grow",attrs:{"cols":"12","md":"3"}},[_c(VCheckbox,{attrs:{"readonly":"","label":"Notwendige Cookies"},model:{value:(_vm.essential),callback:function ($$v) {_vm.essential=$$v},expression:"essential"}})],1),_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c(VCheckbox,{attrs:{"label":"Google Analytics"},model:{value:(_vm.googleAnalytics),callback:function ($$v) {_vm.googleAnalytics=$$v},expression:"googleAnalytics"}})],1),_c(VSpacer),_c(VBtn,{attrs:{"outlined":""},on:{"click":_vm.close}},[_vm._v(" Weiter ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }