import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"flat":"","color":"transparent"}},[_c(VCardTitle,{staticStyle:{"word-break":"break-word"}},[_c('h2',{staticClass:"mb-4",staticStyle:{"font-size":"39px","font-weight":"normal"}},[_vm._v(_vm._s(_vm.stepsText.title))])]),_c(VCardText,[_c(VRow,{staticClass:"fill-height mt-10 mb-5"},_vm._l((this.stepsText.bullets),function(bullet,index){return _c(VCol,{key:bullet.id,class:_vm.$vuetify.breakpoint.mdAndDown ? 'mb-10' : '',attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c(VCard,{staticClass:"fill-height card-outter"},[_c(VCardTitle,[_c(VRow,[_c(VCol,{attrs:{"cols":"9"}},[_c('span',{class:_vm.$vuetify.breakpoint.lg ? 'text-h6 mb-1 font-weight-bold\'' :  'text-h5 mb-1 font-weight-bold',style:(_vm.mouseover ? 'cursor: pointer' : ''),on:{"click":function($event){return _vm.$vuetify.goTo('#products')},"mouseover":function($event){_vm.mouseover = true},"mouseleave":function($event){_vm.mouseover = false}}},[_vm._v(" "+_vm._s(bullet.title)+" ")])])],1)],1),_c(VAvatar,{style:(_vm.$vuetify.breakpoint.smAndDown ? 'right: 20px; top: -72px; position: absolute' : 'right: 20px; top: -60px; position: absolute'),attrs:{"tile":"","size":"105"}},[_c(VImg,{attrs:{"src":_vm.$vuetify.theme.dark ? require('@/assets/icons/' + bullet.picture) : require('@/assets/icons/light_' + bullet.picture)}})],1),_c(VCardText,{domProps:{"innerHTML":_vm._s(bullet.body)}}),(index === 0)?_c(VCardActions,{staticClass:"card-actions"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","href":"tel: 06171 95 69 99 96"}},[_vm._v("06171 95 69 99 96")])],1):_vm._e()],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }