<template>
  <iframe
      width="100%"
      height="100%"
      :src="result"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
  ></iframe>
</template>

<script>
export default {
  name: "YoutubeEmbed",
  props: {
    src: {
      type: String,
      required: true
    },
  },
  data: () => ({
    result: ""
  }),
  methods: {
    loadURL () {
      const youtubeEmbedTemplate = 'https://www.youtube.com/embed/'
      const url = this.src.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
      // console.log("url", url)
      const YId = undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0]
      // console.log("YId", YId)
      if (YId === url[0]) {
        // console.log("not a youtube link")
      } else {
        // console.log("it's  a youtube video")
      }
      const topOfQueue = youtubeEmbedTemplate.concat(YId)
      // console.log("topOfQueue", topOfQueue)
      this.result = topOfQueue
    }
  },
  mounted() {
    this.loadURL();
  },
  computed: {
  }
}
</script>

<style scoped>

</style>
