<template>
  <v-container>
    <v-card color="primary">
      <v-card-title style="word-break: break-word">
        <h2 style="font-size: 39px; font-weight: normal" class="mb-4"> {{ FAQ.title }} </h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" v-for="(f, index) in FAQ.text" v-bind:key="f.title[1] + index + 'FAQ'">
                <v-card >
                  <v-card-title > {{ f.title }} </v-card-title>
                  <v-card-text> {{ f.body }} </v-card-text>
                </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
export default {
  name: "FAQ",
  data: () => ({}),
  methods: {},
  computed: {
    FAQ() {
      return this.$t("FAQ");
    }
  }
}
</script>

<style scoped>

</style>
