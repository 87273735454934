<template>
  <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      app
      width="281"
  >
    <v-list-item>
      <v-list-item-avatar>
        <v-img :src="require('@/assets/Logo.png')"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>MPU Vorbereitung Online</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item v-for="buttons in button"
                   :key="buttons.id"
                   link @click="goTo(buttons.ref)">
        <v-list-item-title>
          {{ buttons.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Drawer",
  data: () => ({
    drawer: false,
    button: [
      {
        title: "Home",
        ref: "#home"
      },
      {
        title: "Hilfe in 3 Schritten",
        ref: "#steps"
      },
      {
        title: "Produkte",
        ref: "#products"
      },
      {
        title: "Interview",
        ref: "interview"
      }
      ,
      {
        title: "Unser Team",
        ref: "team"
      }
    ]
  }),
  methods: {
    toggleDrawer(){
      this.drawer = !this.drawer
    },
    goTo(ref){
      if (ref[0] === "#"){
        this.$vuetify.goTo(ref)
      } else {
        this.$router.push(ref)
      }
      this.drawer = false
    }
  },
  computed: {
  }
}
</script>

<style scoped>

</style>
