import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from "./router";
import i18n from './plugins/i18n'
import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App),
  mounted: () => {
    setTimeout(function () {
      document.dispatchEvent(new Event("x-app-rendered"))
    }, 2500)

  },
}).$mount('#app');
