<template>
    <v-container class="mt-13">
    <v-container>
          <v-card>
            <v-card-title>Allgemeine Geschäftsbedingungen (AGB) für das Portal: MPU-Vorbereitungskurs-Online</v-card-title>
            <v-card-subtitle>Aschaffenburg, den 04.12.2020</v-card-subtitle>
            <v-card-text>
              <div v-for="line in impressum" v-bind:key="line.title">
                <span v-html="line"/>
              </div>
            </v-card-text>
            <v-card-text>
              <div v-for="agb in agbs" v-bind:key="agb.title">
                <h2> {{ agb.title }} </h2>
                <v-list>
                  <v-list-item v-for="subAgb in agb.subAgb" v-bind:key="subAgb.title">
                    <v-list-item-content>
                      <v-list-item-title> {{ subAgb.title }}</v-list-item-title>
                      <span v-html="subAgb.text"></span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
    </v-container>
    </v-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'AGBs - MPU Vorbereitungskurs Online',
    }
  },
  name: "AGB",
  data: () => ({
    impressum: [
      "STAG Dienstleistungsgesellschaft mbH",
      "Frohsinnstraße 30",
      "63739 Aschaffenburg",
      "E-Mail: <a href='mailto:info@stagrecht.de' >info@stagrecht.de</a>",
      "<br />",
      "Amtsgericht Aschaffenburg, HRB 15567",
      "Geschäftsführer: Stefan Weiland USt-ID: 	DE323383755"
    ],
    agbs: [
      {
        title: "Geltungsbereich, Begriffsbestimmung", subAgb: [
          {
            title: "(1)",
            text: "Diese AGB bilden die Grundlage für die Bereitstellung der technischen Infrastruktur zur Kommunikation und " +
                "Abwicklung der ausschließlich außergerichtlichen Beratungsleistungen der kooperierenden Rechtsanwälte " +
                "und den Kunden (im Folgenden: „Kunden) der STAG Dienstleistungsgesellschaft mbH (im Folgenden: " +
                "„STAG“) durch die STAG mittels des Nutzerportals, in welchem sich die Kunden registrieren (nachfolgend " +
                "„Portal“). Diese AGB regeln nur das Verhältnis zwischen dem Kunden und der STAG. "
          },
          {
            title: "(2)",
            text: "Die AGB können auf der Webseite jederzeit auf der Detailseite „AGB“ eingesehen werden. Dort besteht die " +
                "Möglichkeit, die AGB zu speichern oder auszudrucken."
          },
          {
            title: "(3)",
            text: "Abweichende Bedingungen akzeptiert die STAG nicht, es wird vorsorglich bereits jetzt anderweitigen " +
                "vertraglichen Bedingungen widersprochen."
          }
        ]
      },
      {
        title: "Vertragsschluss", subAgb: [
          {
            title: "(1)",
            text: "Es wird keine Mitgliedschaft begründet. Die Leistung der STAG beschränkt sich auf die Vermittlung einzelner " +
                "Beratungsleistungen der Kooperationsanwälte. " +
                "Um das Portal der STAG nutzen zu können, ist das Ausfüllen des Anmeldeformulars unter Angabe der " +
                "Kontaktdaten sowie die Zustimmung zu den vorliegenden AGB erforderlich."
          },
          {
            title: "(2)",
            text: "Für den Fall, dass der Kunde das Anmeldeformular auf der Webseite ausfüllt gilt, dass er mit dem Abschluss " +
                "des Checkouts ein verbindliches Angebot zum Abschluss eines Nutzungsvertrages über die Angebote der " +
                "STAG abgibt. Vor dem Abschicken des Anmeldeformulars kann der Nutzer seine Angaben jederzeit ändern " +
                "und einsehen. Der Nutzungsvertrag kommt durch das Versenden einer Bestätigungs-E-Mail der STAG " +
                "zustande. STAG behält sich ausdrücklich vor, weiterführende Informationen und Unterlagen beim Nutzer " +
                "abzufragen und Anfragen ohne Angabe von Gründen abzulehnen."
          },
          {
            title: "(3)",
            text: "In anderen Fällen kommt der Vertrag in der Regel mit sonstiger schriftlicher Einwilligung des Kunden " +
                "zustande."
          }
        ]
      },
      {
        title: "Leistungen der STAG ", subAgb: [
          {
            title: "(1)",
            text: "Mit Abschluss des Kaufs der kostenpflichtigen Beratungsleistung gewährt die STAG dem Nutzer einen " +
                "sofortigen Zugriff auf die im Portal gebuchten Leistungen. Die Leistungen sind auf der Website „Ihr " +
                "Reiserecht“ umfassend und abschließend beschrieben. "
          },
          {
            title: "(2)",
            text: "STAG erbringt keinerlei Rechtsberatungsleistungen und stellt mittels des Portals lediglich eine Plattform zur " +
                "Verfügung, um rechtliche Einzelberatungen durch einen Kooperationsanwalt zu ermöglichen und diese " +
                "technisch abzuwickeln. "
          },
          {
            title: "(3)",
            text: "STAG ist berechtigt, den Zugang zu einzelnen Inhalten zu sperren oder Inhalte zu löschen, sofern ein" +
                "begründeter Verdacht auf eine missbräuchliche Nutzung besteht oder Drittrechte potentiell verletzt werden " +
                "können. Ferner können Kunden mit sofortiger Wirkung von der Nutzung des Portals ausgeschlossen " +
                "werden, wenn der Kunde wesentliche Pflichten oder geltende Gesetze verletzt."
          }
        ]
      },
      {
        title: "Rechte und Pflichten des Kunden", subAgb: [
          {
            title: "(1)",
            text: "Der Kunde verpflichtet sich, sämtliche Informationen und Dokumente stets vollständig und korrekt zu " +
                "überlassen, um eine qualifizierte Beratungsleistung durch den Kooperationsanwalt zu ermöglichen. Eine" +
                "Dokumentenprüfung findet nicht statt, die Beratungsleistung erfolgt nur auf Grundlage der zur Verfügung " +
                "gestellten Dokumente und Informationen. Die Nutzung der Angebote der STAG ist nur unbeschränkt " +
                "geschäftsfähigen natürlichen Personen gestattet. Ein Altersnachweis kann bei berechtigten Zweifeln " +
                "gefordert werden."
          },
          {
            title: "(2)",
            text: "Der Kunde stellt die STAG von allen Ansprüchen Dritter aus einer Drittrechtsverletzung (z.B. Datenschutz- " +
                "und Persönlichkeitsrechtsverletzungen), die gegen die STAG im Zusammenhang mit der Nutzung des Portals " +
                "durch den Kunden erhoben werden, auf erstes Anfordern hin frei. Der Kunde hat eine ihm bekannt " +
                "werdende Erhebung von Ansprüchen Dritter, die im Zusammenhang mit der Nutzung der Angebote der " +
                "STAG steht, unverzüglich mitzuteilen. STAG  ist berechtigt, selbst geeignete Maßnahmen zur Abwehr von " +
                "Ansprüchen Dritter oder zur Rechtsverfolgung vorzunehmen. Die Freistellung beinhaltet auch den Ersatz " +
                "angemessener Kosten, die der STAG durch eine Rechtsverfolgung/-verteidigung entstehen bzw. entstanden " +
                "sind."
          }
        ]
      },
      {
        title: "Zahlungsbedingungen", subAgb: [
          {
            title: "(1)",
            text: "Die innerhalb des Portals aufgeführten Preise stellen Endpreise dar. Sie beinhalten im Zweifel alle" +
                "Preisbestandteile einschließlich etwaig anfallender Steuern in der jeweils gültigen Fassung."
          },
          {
            title: "(2)",
            text: "Das vereinbarte Entgelt für die einzelnen Beratungen ist eine Pauschale und ist mit der jeweiligen " +
                "zahlungspflichtigen Bestellung im Voraus fällig."
          },
          {
            title: "(3)",
            text: "Eine gesonderte Gebühr für die vom Kunden über das Portal gebuchte Einzelberatung des " +
                "Kooperationsanwalts entsteht nicht. Für anwaltliche Beratungen oder Tätigkeiten, die über die im Portal zu " +
                "buchenden Leistungen hinausgehen, ist mit dem jeweiligen Rechtsanwalt ein gesondertes" +
                "Mandatsverhältnis abzuschließen. Dafür anfallende Kosten und Gebühren sind nicht mit den hier " +
                "vereinbarten Preisen abgegolten."
          }
        ]
      },
      {
        title: "Haftung", subAgb: [
          {
            title: "(1)",
            text: "STAG haftet uneingeschränkt für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, " +
                "die auf einer vorsätzlichen oder fahrlässigen Pflichtverletzung beruhen sowie für sonstige Schäden, die auf " +
                "einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung sowie auf Arglist beruhen. Darüber hinaus " +
                "haftet die STAG uneingeschränkt für Schäden, die von der Haftung nach zwingenden gesetzlichen " +
                "Vorschriften, insbes. dem ProdHaftG, umfasst sind."
          },
          {
            title: "(2)",
            text: "Für solche Schäden, die nicht von § 7 Abs. 1 erfasst und die durch einfache oder leichte Fahrlässigkeit" +
                "verursacht werden, haftet die STAG nur, soweit diese Fahrlässigkeit die Verletzung von Vertragspflichten" +
                "betrifft, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf " +
                "deren Einhaltung der Kunde regelmäßig vertrauen darf (sog. Kardinalpflichten). Dabei beschränkt sich die " +
                "Haftung der STAG auf die vertragstypischen vorhersehbaren Schäden."
          },
          {
            title: "(3)",
            text: "Die vorstehenden Regelungen gelten auch zugunsten der Mitarbeiter, Vertreter und Erfüllungsgehilfen der " +
                "STAG."
          },
          {title: "(4)", text: "STAG haftet ausdrücklich nicht für Beratungsfehler der Kooperationsanwälte."}
        ]
      },
      {
        title: "Schlussbestimmungen", subAgb: [
          {
            title: "(1)",
            text: "STAG ist berechtigt, diese AGB jederzeit zu ändern. Im Falle einer Änderung der AGB wird der Kunde " +
                "hierüber schriftlich, per E-Mail oder in ähnlicher Form (z.B. durch eine Benachrichtigung beim Login) in " +
                "Kenntnis gesetzt. Die Zustimmung zu den Änderungen gilt dabei als erteilt, sofern der Nutzer den " +
                "Änderungen den AGB nicht binnen vier Wochen nach Zugang der Änderungsmitteilung widerspricht. STAG " +
                "wird den Kunden zusammen mit der Änderungsmitteilung nochmals ausdrücklich auf diese Folge eines " +
                "unterlassenen Widerspruchs hinweisen. Für den Fall, dass der Kunde seine Zustimmung zu den Änderungen " +
                "verweigert, wird das Nutzungsverhältnis noch bis zur Fertigstellung einer aktuellen Anfrage an den " +
                "Kooperationsanwalt zu den bisherigen Bedingungen fortgeführt. Eine erneute Nutzung wird dem Nutzer " +
                "anschließend jedoch nur noch zu den jeweils aktuellen AGB angeboten."
          },
          {title: "(2)", text: "Es gilt das Recht der Bundesrepublik Deutschland. "},
          {
            title: "(3)",
            text: "STAG ist berechtigt, alle in den Anwendungsbereich dieser AGB fallenden Rechte und Pflichten ganz oder " +
                "teilweise mit befreiender Wirkung auf einen Dritten zu übertragen. Der Nutzer stimmt einer solchen " +
                "Vertragsübernahme hiermit bereits jetzt zu. "
          },
          {
            title: "(4)",
            text: "Sämtliche Änderungen oder Ergänzungen der AGB bedürfen der Textform. Das Textformerfordernis gilt auch " +
                "für die Aufhebung des Textformerfordernisses selbst."
          },
          {
            title: "(5)",
            text: "Der Kunde ist nicht berechtigt, gegenüber Forderungen der STAG aufzurechnen, es sei denn, sie sind " +
                "rechtskräftig festgestellt oder unbestritten."
          },
          {
            title: "(6)",
            text: "Sofern es sich bei dem Kunden um einen Kaufmann, eine juristische Person des öffentlichen Rechts oder um " +
                "ein öffentlich-rechtliches Sondervermögen handelt, ist Gerichtsstand für alle Streitigkeiten aus dem " +
                "Vertragsverhältnis der Sitz der STAG. Erfüllungsort ist der Sitz von STAG."
          },
          {
            title: "(7)",
            text: "Hat der Kunde keinen allgemeinen Gerichtsstand in Deutschland oder in einem anderen EU-Mitgliedstaat" +
                "oder hat der Kunde seinen Wohnsitz nach Wirksamwerden dieser AGB in ein Land außerhalb der EU verlegt " +
                "oder ist sein Wohnsitz oder gewöhnlicher Aufenthaltsort im Zeitpunkt der Klageerhebung nicht bekannt, ist " +
                "ausschließlicher Gerichtsstand für sämtliche Streitigkeiten aus diesem Vertrag der Geschäftssitz der STAG."
          },
          {
            title: "(8)",
            text: "Zur alternativen außergerichtlichen Beilegung von Streitigkeiten über im Internet abgeschlossene Kauf- und " +
                "Dienstleistungsverträge zwischen Verbrauchern und Unternehmen hat die Europäische Kommission eine " +
                "europäische Online-Streitbeilegungsplattform eingerichtet, die hier zu erreichen ist: " +
                "<a href='https://webgate.ec.europa.eu/odr/main/?event=main.home.show.'> webgate.ec.europa.eu </a> <br />" +
                "Die STAG erklärt bereits jetzt, dass sie an einem Streitbeilegungsverfahren vor dieser " +
                "Verbraucherschlichtungsstelle <strong>nicht</strong> teilnimmt."
          },
          {
            title: "(9)",
            text: "Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden, bleibt die Wirksamkeit der " +
                "restlichen Bestimmungen davon unberührt. Die unwirksame Bestimmung ist sodann durch eine Regelung zu " +
                "ersetzen, die der wirtschaftlichen Zielsetzung der Parteien am nächsten kommt. Entsprechendes gilt, wenn " +
                "diese AGB Lücken enthalten."
          }
        ]
      },
    ]
  })
}
</script>

<style scoped>

</style>
