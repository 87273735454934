<template>
  <v-container>
    <v-card flat color="transparent">
      <v-card-title style="word-break: break-word">
        <h2 style="font-size: 39px; font-weight: normal" class="mb-4">{{ stepsText.title }}</h2>
      </v-card-title>
      <v-card-text>
        <v-row class="fill-height mt-10 mb-5">
          <v-col v-bind:key="bullet.id" v-for="(bullet, index) in this.stepsText.bullets" cols="12" xs="12" sm="12" md="12" lg="4" xl="4" :class="$vuetify.breakpoint.mdAndDown ? 'mb-10' : ''">
            <v-card class="fill-height card-outter">
              <v-card-title>
                <v-row>
                  <v-col cols="9">
                    <span :class="$vuetify.breakpoint.lg ? 'text-h6 mb-1 font-weight-bold\'' :  'text-h5 mb-1 font-weight-bold'" :style="mouseover ? 'cursor: pointer' : ''" @click="$vuetify.goTo('#products')" @mouseover="mouseover = true" @mouseleave="mouseover = false">
                  {{ bullet.title }}
                </span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-avatar tile size="105" :style="$vuetify.breakpoint.smAndDown ? 'right: 20px; top: -72px; position: absolute' : 'right: 20px; top: -60px; position: absolute'">
                <v-img class="" :src="$vuetify.theme.dark ? require('@/assets/icons/' + bullet.picture) : require('@/assets/icons/light_' + bullet.picture)" />
              </v-avatar>
              <v-card-text v-html="bullet.body"/>
              <v-card-actions class="card-actions" v-if="index === 0">
                <v-spacer /><v-btn color="primary" href="tel: 06171 95 69 99 96">06171 95 69 99 96</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

export default {
name: "steps",
  data: () => ({
    mouseover: false
  }),
  computed: {
    stepsText(){
      return JSON.parse(JSON.stringify(this.$t('Landing.steps')))
    }
  },
}
</script>

<style scoped>
.card-outter {
  position: relative;
  padding-bottom: 10px;
}
.card-actions {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
