import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"color":"primary"}},[_c(VCardTitle,{staticStyle:{"word-break":"break-word"}},[_c('h2',{staticClass:"mb-4",staticStyle:{"font-size":"39px","font-weight":"normal"}},[_vm._v(" "+_vm._s(_vm.FAQ.title)+" ")])]),_c(VCardText,[_c(VRow,_vm._l((_vm.FAQ.text),function(f,index){return _c(VCol,{key:f.title[1] + index + 'FAQ',attrs:{"cols":"12","md":"6"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(f.title)+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(f.body)+" ")])],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }