import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"color":"primary","flat":"","height":"100%"}},[_c(VCardTitle,{staticStyle:{"word-break":"break-word"}},[_c('h2',{staticClass:"mb-4",staticStyle:{"font-size":"39px","font-weight":"normal"}},[_vm._v(_vm._s(_vm.advantages.title))])]),_c(VCardText,[_c(VContainer,{attrs:{"grid-list-md":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":"","row":"","fill-height":""}},[_c(VExpansionPanels,{attrs:{"multiple":""}},_vm._l((_vm.advantages.bullets),function(subject,index){return _c(VFlex,{key:subject.title,attrs:{"xs12":"","sm12":"","md6":"","lg3":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{style:(_vm.$vuetify.breakpoint.mdAndUp ? 'height: 120px' : _vm.$vuetify.breakpoint.smAndDown ? '': 'height: 80px')},[_c('span',{staticStyle:{"font-size":"19px","word-break":"break-word"},domProps:{"innerHTML":_vm._s(subject.title)}})]),_c(VExpansionPanelContent,[_c('div',{attrs:{"id":'advantage' + index}},[_c('span',{domProps:{"innerHTML":_vm._s(subject.body)}}),_c(VSpacer)],1)])],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }