<template>
  <v-container>
    <v-card flat color="transparent">
      <v-card-title style="word-break: break-word">
        <h1 style="font-size: 39px; font-weight: normal" class="mb-4"> Blogbeiträge </h1>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" v-for="news in Blogs.news" :key="news.id">
            <v-card color="primary" v-if="news.type === 'iv'">
              <v-card-title> {{ news.title }} </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout align-center justify-center row fill-height>
                    <v-flex style="max-width: 750px">
                      <v-card>
                        <v-card-text v-html="news.preview"></v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="openDialog(news.id)"> Zum Interview </v-btn>
              </v-card-actions>
            </v-card>
            <v-card color="primary" v-if="news.type === 'blog'">
              <v-card-title> {{ news.title }} </v-card-title>
              <v-card-subtitle> {{ news.subtitle }} </v-card-subtitle>
              <v-card-subtitle> {{ news.date }} </v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="openDialog(news.id)"> Zum Blogbeitrag </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialog"
    >
     <Blog v-bind:News="blog" />
    </v-dialog>
  </v-container>
</template>

<script>
import Blog from "./Blog";
export default {
  name: "BlogLayout",
  components: { Blog },
  data: () => ({
    dialog: false,
    blog: {},
  }),
  methods: {
    checkForId(){
      if (this.$route.query.id){
        this.openDialog(this.$route.query.id)
      }
    },
    openDialog(id){
      this.blog = this.$t("News.news")[id];
      console.log(this.$t("News.news")[id])
      this.dialog = true
    },
  },
  computed: {
    Blogs() {
      return this.$t("News");
    }
  },
  mounted(){
    this.checkForId()
  }
}
</script>

<style scoped>

</style>
