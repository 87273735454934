<template>
  <v-app>
    <my-bar v-on:toggleDrawer="toggleDrawer()"/>
    <drawer ref="drawer" />
    <cookie />
    <v-main>
      <router-view/>
    </v-main>
    <my-footer/>
  </v-app>
</template>

<script>
  import myBar from './components/core/Bar'
  import drawer from './components/core/Drawer'
  import myFooter from './components/core/Footer'
  import Cookie from "@/components/core/Cookie";
export default {
  name: 'App',
  components: {
    Cookie,
    myBar,
    myFooter,
    drawer
  },
  methods: {
    re: function (link) {
      if(link.slice(0,1) === "#"){
        this.$router.push("/");
        this.$vuetify.goTo(link,)
      } else {
        this.$router.push(link);
      }
    },
    toggleDrawer(){
      this.$refs.drawer.toggleDrawer()
    },
    route: function(){
      // eslint-disable-next-line no-console
      return this.$route.fullPath === "/";
    }

  },
  data: () => ({
  }),
};
</script>

<style lang="sass">
  .v-card__text, .v-card__title
    word-break: normal !important

</style>
