export function getMediaLanguageURL(language) {
    return (
        "https://media.stagrecht.de/locales/" +
        language +
        ".json"
    );
}
export function getPaymentURL() {
    return (
        "https://payment.stagrecht.de/"
    );
}

export function getFormURL() {
    return (
      "https://form.stagrecht.de/"
    );
}
