import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{attrs:{"fixed":"","temporary":"","app":"","width":"281"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VListItem,[_c(VListItemAvatar,[_c(VImg,{attrs:{"src":require('@/assets/Logo.png')}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("MPU Vorbereitung Online")])],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":""}},_vm._l((_vm.button),function(buttons){return _c(VListItem,{key:buttons.id,attrs:{"link":""},on:{"click":function($event){return _vm.goTo(buttons.ref)}}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(buttons.title)+" ")])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }