import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"flat":"","color":"transparent"}},[_c(VCardTitle,{staticStyle:{"word-break":"break-word"}},[_c('h2',{staticClass:"mb-4",staticStyle:{"font-size":"39px","font-weight":"normal"}},[_vm._v(" "+_vm._s(_vm.BlogPreview.title)+" ")])]),_c(VCardText,[_c(VRow,_vm._l((_vm.BlogPreview.news),function(news){return _c(VCol,{key:news.id,attrs:{"cols":"12","lg":"4"}},[(news.type === 'iv')?_c(VCard,{attrs:{"color":"primary"}},[_c(VCardTitle,[_vm._v(" "+_vm._s(news.title)+" ")]),_c(VCardText,[_c(VCard,[_c(VCardText,[_c('div',{domProps:{"innerHTML":_vm._s(news.preview)}})])],1),_c('div',{staticClass:"mt-7 ml-3 mr-3"},[_c(VRow,[_c(VCard,{staticClass:"elevation-2"},[_c(VListItem,{staticClass:"mt-2"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h5",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(news.persons[0])+" ")])],1),_c(VListItemAvatar,{attrs:{"size":"50"}},[_c('img',{attrs:{"alt":"MPU Vorbereitungskurs Online - Stefan J. Weiland","src":require("../assets/AvatarStef.jpeg")}})])],1),_c(VCardText,[_vm._v(" "+_vm._s(news.text[0].question)+" ")])],1)],1),_c(VRow,{staticClass:"mt-4"},[_c(VCard,{staticClass:"elevation-2"},[_c(VListItem,{staticClass:"mt-2"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h5",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(news.persons[1])+" ")])],1),_c(VListItemAvatar,{attrs:{"size":"50"}},[_c('img',{attrs:{"alt":"MPU Vorbereitungskurs Online - Rechtsanwalt Tobias Jung","src":require("../assets/AvatarJung.png")}})])],1),_c(VCardText,{domProps:{"innerHTML":_vm._s(news.text[0].answer)}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VDialog,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Zum Interview ")])]}},{key:"default",fn:function(dialog){return [_c('Blog',{attrs:{"News":news,"dialog":dialog}})]}}],null,true)})],1)],1):_vm._e(),(news.type === 'blog')?_c(VCard,{attrs:{"color":"primary"}},[_c(VCardTitle,[_vm._v(" "+_vm._s(news.title)+" ")]),_c(VCardSubtitle,[_vm._v(" "+_vm._s(news.subtitle)+" ")]),_c(VCardSubtitle,[_vm._v(" "+_vm._s(news.date)+" ")]),_c(VCardText,[_c(VCard,[_c(VCardText,_vm._l((1),function(index){return _c('div',{key:news.text[index-1].title[0]},[_c('h4',{staticClass:"text-h5",domProps:{"innerHTML":_vm._s(news.text[index-1].title)}}),_c('p',{staticClass:"text-body-1",domProps:{"innerHTML":_vm._s((news.text[index-1].body.split('.')[0] + '.') + (news.text[index-1].second ? news.text[index-1].body.split('.')[1] + '.' : ''))}})])}),0)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VDialog,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Zum Blogbeitrag ")])]}},{key:"default",fn:function(dialog){return [_c('Blog',{attrs:{"News":news,"dialog":dialog}})]}}],null,true)})],1)],1):_vm._e()],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }