import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"flat":"","color":"transparent"}},[_c(VCardTitle,{staticStyle:{"word-break":"break-word"}},[_c('h1',{staticClass:"mb-4",staticStyle:{"font-size":"39px","font-weight":"normal"}},[_vm._v(" Blogbeiträge ")])]),_c(VCardText,[_c(VRow,_vm._l((_vm.Blogs.news),function(news){return _c(VCol,{key:news.id,attrs:{"cols":"12","md":"4"}},[(news.type === 'iv')?_c(VCard,{attrs:{"color":"primary"}},[_c(VCardTitle,[_vm._v(" "+_vm._s(news.title)+" ")]),_c(VCardText,[_c(VContainer,{attrs:{"grid-list-md":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":"","row":"","fill-height":""}},[_c(VFlex,{staticStyle:{"max-width":"750px"}},[_c(VCard,[_c(VCardText,{domProps:{"innerHTML":_vm._s(news.preview)}})],1)],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{on:{"click":function($event){return _vm.openDialog(news.id)}}},[_vm._v(" Zum Interview ")])],1)],1):_vm._e(),(news.type === 'blog')?_c(VCard,{attrs:{"color":"primary"}},[_c(VCardTitle,[_vm._v(" "+_vm._s(news.title)+" ")]),_c(VCardSubtitle,[_vm._v(" "+_vm._s(news.subtitle)+" ")]),_c(VCardSubtitle,[_vm._v(" "+_vm._s(news.date)+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{on:{"click":function($event){return _vm.openDialog(news.id)}}},[_vm._v(" Zum Blogbeitrag ")])],1)],1):_vm._e()],1)}),1)],1)],1),_c(VDialog,{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('Blog',{attrs:{"News":_vm.blog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }