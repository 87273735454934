import VueRouter from 'vue-router'
import Vue from "vue";

//Views
import Datenschutz from "@/Views/Datenschutz";
import Main from "@/Views/Main";
import Impressum from "@/Views/Impressum";
import Blog from "@/Views/Blog";
import Team from "@/Views/Team";
import AGBs from "@/Views/AGBs";
import Widerrufsrecht from "@/Views/Widerrufsrecht";

Vue.use(VueRouter);

const routes = [
    { path: '/datenschutz', component: Datenschutz},
    { path: '/', component: Main },
    { path: '/team', component: Team },
    { path: '/impressum', component: Impressum },
    { path: '/blog', component: Blog },
    { path: '/widerrufsrecht', component: Widerrufsrecht},
    { path: '/agb', component: AGBs },
];

const router = new VueRouter({
    scrollBehavior: to => {
        if (to.hash) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve({ selector: to.hash });
                }, 1000);
            });
        } else {
            return { x: 0, y: 0 };
        }
    },
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

// https://github.com/MatteoGabriele/vue-analytics
if (localStorage.getItem("GoogleAnalytics") === "true") {
    Vue.use(require("vue-analytics").default, {
        id: "UA-194159194-1",
        router,
        autoTracking: {
            page: true
        }
    });
    window.fbq('init', '324048059507532');
    window.fbq('track', 'PageView');
}

export default router;

