import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-13"},[_c(VContainer,[_c(VCard,[_c(VCardTitle,[_vm._v("Allgemeine Geschäftsbedingungen (AGB) für das Portal: MPU-Vorbereitungskurs-Online")]),_c(VCardSubtitle,[_vm._v("Aschaffenburg, den 04.12.2020")]),_c(VCardText,_vm._l((_vm.impressum),function(line){return _c('div',{key:line.title},[_c('span',{domProps:{"innerHTML":_vm._s(line)}})])}),0),_c(VCardText,_vm._l((_vm.agbs),function(agb){return _c('div',{key:agb.title},[_c('h2',[_vm._v(" "+_vm._s(agb.title)+" ")]),_c(VList,_vm._l((agb.subAgb),function(subAgb){return _c(VListItem,{key:subAgb.title},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(subAgb.title))]),_c('span',{domProps:{"innerHTML":_vm._s(subAgb.text)}})],1)],1)}),1)],1)}),0)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }