<template>
  <v-container>
    <v-card flat color="primary">
      <v-card-title style="word-break: break-word">
        <h1 style="font-size: 39px; font-weight: normal" class="mb-4">{{ interview.title }}</h1>
      </v-card-title>
      <v-card-subtitle style="word-break: break-word" v-html="interview.preview">
            </v-card-subtitle>
      <v-card-text>
                    <v-timeline
                        reverse
                        :dense="$vuetify.breakpoint.smAndDown"
                        v-bind:key="bullet.id" v-for="(bullet) in interview.text"
                    >
                      <v-timeline-item large>
                        <template v-slot:icon>
                          <v-avatar>
                            <img alt="MPU Vorbereitungskurs Online - Stefan J. Weiland" src="../assets/AvatarStef.jpeg">
                          </v-avatar>
                        </template>
                        <v-card class="elevation-2">
                          <v-card-title class="text-h5">
                            {{ interview.persons[0] }}
                          </v-card-title>
                          <v-card-text>
                            {{ bullet.question }}
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                      <v-timeline-item large color="primary">
                        <template v-slot:icon>
                          <v-avatar>
                            <img alt="MPU Vorbereitungskurs Online - Rechtsanwalt Tobias Jung" src="../assets/AvatarJung.png">
                          </v-avatar>
                        </template>
                        <v-card class="elevation-2">
                          <v-card-title class="text-h5">
                            {{ interview.persons[1] }}
                          </v-card-title>
                          <v-card-text v-html="bullet.answer" />
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </v-card-text>
                </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Interview",
  props: {
    interview: {
      required: true
    },
  },
  data: () => ({
  }),
  methods: {
  },
}
</script>

<style scoped>

</style>
