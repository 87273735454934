<template>
  <v-container>
    <v-card flat color="transparent">
      <v-card-title style="word-break: break-word">
        <h2 style="font-size: 39px; font-weight: normal" class="mb-4"> {{ productsText.title }} </h2>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout align-center justify-center row fill-height>
            <v-flex style="max-width: 750px" v-bind:key="product.id" v-for="(product) in this.products">
              <v-card :color="product.color">
                <v-card-text>
                  <v-list>
                    <v-list-item v-for="point in product.points" v-bind:key="point.id">
                      <v-list-item-icon v-if="!point.headline"><v-icon :color="point.icon === 'mdi-close' ? 'error' : 'primary'"> {{ point.icon }} </v-icon></v-list-item-icon>
                      <v-list-item-content v-if="!point.headline" v-html="point.text" />
                      <div v-if="point.headline">
                        <h4 v-html="point.text" ></h4>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn href="tel: 06171 95 69 99 96">Erstberatung 06171 95 69 99 96</v-btn>
                  <!--
                  <v-btn v-if="!$vuetify.breakpoint.smAndDown" @click="route(700)"> {{ product.price }} </v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn v-if="$vuetify.breakpoint.smAndDown" @click="route(700)"> {{ product.price }} </v-btn>
                  -->
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {getPaymentURL} from "@/config/configBuilder";

export default {
  name: "Products",
  data: () => ({
    products: [
      {
        "title": "Standard",
        "points": [
          {
            "text": "Bis zu 6 Stunden Vier-Augengespräche online (à 60 Minuten)",
            "icon": "mdi-check"
          },
          {
            "text": "Begleitende Personen: ein fest zugeordneter Rechtsanwalt und ein mit Fahreignungsprüfungen vertrauter Arzt",
            "icon": "mdi-check"
          },
          {
            "text": "Individuelle Terminvereinbarung je nach Fortschritt",
            "icon": "mdi-check"
          },
          {
            "text": "inkl. Testgespräch, Materialien, Teilnahmebescheinigung",
            "icon": "mdi-check"
          }
        ],
        "color": "primary",
        "price_value": 1299.00,
        "reference_id": 700,
        "price": "jetzt für 1.299 € buchen"
      }
    ]
  }),
  methods: {
    route(reference_id){
      window.location.href =
          getPaymentURL() +
          "?referenceId=" +
          reference_id +
          "&lawArea=MedizinischPsychologischeUntersuchung";
      if (localStorage.getItem("GoogleAnalytics") === "true") {
        window.fbq('trackCustom', 'RedirectToPayment', {
          product: "MedizinischPsychologischeUntersuchung",
          product_id: 700
        });
      }
    }
  },
  computed: {
    productsText(){
      return JSON.parse(JSON.stringify(this.$t('MedizinischPsychologischeUntersuchung.products')))
    },
  },
}
</script>

<style scoped>

</style>
