import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"flat":"","color":"transparent"}},[_c(VCardTitle,{staticStyle:{"word-break":"break-word"}},[_c('h2',{staticClass:"mb-4",staticStyle:{"font-size":"39px","font-weight":"normal"}},[_vm._v(" "+_vm._s(_vm.productsText.title)+" ")])]),_c(VCardText,[_c(VContainer,{attrs:{"grid-list-md":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":"","row":"","fill-height":""}},_vm._l((this.products),function(product){return _c(VFlex,{key:product.id,staticStyle:{"max-width":"750px"}},[_c(VCard,{attrs:{"color":product.color}},[_c(VCardText,[_c(VList,_vm._l((product.points),function(point){return _c(VListItem,{key:point.id},[(!point.headline)?_c(VListItemIcon,[_c(VIcon,{attrs:{"color":point.icon === 'mdi-close' ? 'error' : 'primary'}},[_vm._v(" "+_vm._s(point.icon)+" ")])],1):_vm._e(),(!point.headline)?_c(VListItemContent,{domProps:{"innerHTML":_vm._s(point.text)}}):_vm._e(),(point.headline)?_c('div',[_c('h4',{domProps:{"innerHTML":_vm._s(point.text)}})]):_vm._e()],1)}),1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"href":"tel: 06171 95 69 99 96"}},[_vm._v("Erstberatung 06171 95 69 99 96")])],1)],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }