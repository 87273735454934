<template>
    <v-main style="padding: 0">
        <Home id="home"/>
        <About id="about"/>
        <Advantages id="advantages" />

        <Steps id="steps" />
        <v-img height="300" alt="MPU Vorbereitung Online –  Jetzt Beratung vereinbaren" title="MPU Vorbereitung Online" :src="require('@/assets/mpu-vorbereitung-online.jpg')"></v-img>

        <!--
        <v-parallax height="300" :src="require('@/assets/Footer.jpg')"></v-parallax>
        -->
        <Products id="products" />
        <BlogPreview id="blog" />

      <Interview id="interview" v-bind:interview="InterviewData"/>
      <FAQ id="faq"/>
    </v-main>
</template>

<script>
    import Home from '@/components/Home';
    import About from '@/components/About'
    import Steps from "@/components/Steps";
    import Advantages from "@/components/Advantages";
    import Products from "@/components/Products";
    import Interview from "@/components/Interview";
    import BlogPreview from "@/components/BlogPreview";
    import FAQ from "../components/FAQ";

    export default {
        name: 'App',
      metaInfo() {
          return {
            title: 'MPU Vorbereitungskurs Online –  Meistern Sie erfolgreich Ihre MPU',
            meta: [{ name: 'description', content:  'Mit dem MPU Vorbereitungskurs Online steigern Sie Ihre MPU Erfolgschancen signifikant. ✔️ Kostenlose Erstberatung ✔️ Professionelle Betreuung ✔️' }
            ]
          }
          },
        components: {
          FAQ,
          Interview,
          BlogPreview,
          Products,
          Advantages,
          Steps,
            Home,
            About,
        },
        data: () => ({
        }),
      computed: {
        InterviewData() {
          return this.$t("Interview");
        }
      }
    };
</script>
