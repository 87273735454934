<template>
  <v-container>
      <v-card flat color="transparent">
        <v-card-title style="word-break: break-word">
          <h1 style="font-size: 39px; font-weight: normal" class="mb-4"><strong>MPU Vorbereitungskurs Online</strong> – fair und professionell</h1>
        </v-card-title>
        <v-card-subtitle style="word-break: break-word">
          <h3>In Zusammenarbeit mit Medical Consulting Partners, Köln</h3>
        </v-card-subtitle>
        <v-card-text>
            <div v-html="intro.body1"/>
            <br />
          <div v-html="intro.body2"/>
        </v-card-text>
      </v-card>
  </v-container>
</template>
<script>
    export default {
        data: () => ({}),
      computed: {
        intro(){
          return JSON.parse(JSON.stringify(this.$t('Landing.intro')))
        }
      },
    };
</script>

