<template>
  <v-container class="mt-13">
    <TeamComponent />
  </v-container>
</template>

<script>
import TeamComponent from '@/components/Team'
export default {
  name: "Team",
  metaInfo: {
    title: 'Team - MPU Vorbereitungskurs Online',
  },
  components: {
    TeamComponent
  }
}
</script>

<style scoped>

</style>
