<template>
    <div>
             <interview v-bind:interview="News" v-if="News.type === 'iv'" />
            <v-card light color="primary" v-if="News.type === 'blog'">
              <v-card-title> {{ News.title }} </v-card-title>
              <v-card-subtitle v-if="News.subtitle"> {{ News.subtitle }} </v-card-subtitle>
              <v-card-subtitle> {{ News.date }} </v-card-subtitle>
                <v-card-text>
                  <div v-for="bullet in News.text" v-bind:key="bullet.title[0]">
                    <h4 class="text-h5" v-html="bullet.title"></h4>
                    <p class="text-body-1" v-html="bullet.body"></p>
                  </div>
                </v-card-text>
              </v-card>
    </div>
</template>

<script>
import Interview from "../components/Interview";
export default {
  name: "Blog",
  components: {Interview},
  props: {
    News: {
      required: true
    },
  },
  data: () => ({
  }),
  methods: {
  }
}
</script>

<style scoped>

</style>
