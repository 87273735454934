<template>
  <div>
    <v-img src="../assets/mpu-vorbereitungskurs-online.jpg" alt="Erfolgschancen bei der MPU signifikant mit unserem MPU Vorbereitungskurs online steigern" title="MPU Vorbereitungskurs Online von STAG" :height="$vuetify.breakpoint.smAndDown ? '350px' : '700px'">
      <!--
      <News v-if="!$vuetify.breakpoint.mobile"/>
      -->
    </v-img>
  <v-layout
      class="mt-4"
      fill-height
      align-center
      justify-center
      style="height: 100%"
  >
    <!--
  <v-avatar
      class="mt-15"
          tile
  size=250>
          <img src="../assets/Logo.png" alt="Logo">
  </v-avatar>
   <h1 class="display-2 font-weight-thin mb-4" style="color: #818181; margin-top: 8%">STAG</h1>
      <h4 class="subheading">
        Ihr neues Portal für Rechtsberatung
      </h4>
   -->
    <v-container>
      <v-row>
        <v-col md="2"></v-col>
        <v-col cols="12" md="4">
          <v-card class="d-flex flex-column" color="primary" height="100%">
            <v-card-title>
              Jetzt persönliche Beratung abholen
            </v-card-title>
            <v-card-subtitle>
              <strong>Täglich</strong>  <span style="margin-left: 8px">8 – 18 Uhr</span>
            </v-card-subtitle>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn x-large text href="tel:06171 95 69 99 96" style="font-size: 2rem">06171 – 95 69 99 96</v-btn>
               </v-card-actions>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn x-large text href="mailto:mpu-online@stag-service.de?subject=Kontaktanfrage%20von%20der%20Webseite&amp;body=Sehr%20geehrter%20Herr%20Weiland,%0D%0A%0D%0A" >E-Mail</v-btn>

            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card class="d-flex flex-column" >
            <v-card-title>
              Youtube Video
            </v-card-title>
            <v-spacer></v-spacer>
            <v-card-text>
              <YoutubeEmbed v-bind:src="'https://www.youtube.com/watch?v=QFxazgHFRU8&'" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </v-layout>
  </div>
</template>

<script>
//import News from "@/components/News";

import YoutubeEmbed from "./YoutubeEmbed";
export default {
  components: {
    YoutubeEmbed
    //News
  },
  data: () => ({

  }),
};
</script>
