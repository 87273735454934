import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VImg,{attrs:{"src":require("../assets/mpu-vorbereitungskurs-online.jpg"),"alt":"Erfolgschancen bei der MPU signifikant mit unserem MPU Vorbereitungskurs online steigern","title":"MPU Vorbereitungskurs Online von STAG","height":_vm.$vuetify.breakpoint.smAndDown ? '350px' : '700px'}}),_c(VLayout,{staticClass:"mt-4",staticStyle:{"height":"100%"},attrs:{"fill-height":"","align-center":"","justify-center":""}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"md":"2"}}),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VCard,{staticClass:"d-flex flex-column",attrs:{"color":"primary","height":"100%"}},[_c(VCardTitle,[_vm._v(" Jetzt persönliche Beratung abholen ")]),_c(VCardSubtitle,[_c('strong',[_vm._v("Täglich")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"8px"}},[_vm._v("8 – 18 Uhr")])]),_c(VSpacer),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticStyle:{"font-size":"2rem"},attrs:{"x-large":"","text":"","href":"tel:06171 95 69 99 96"}},[_vm._v("06171 – 95 69 99 96")])],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"x-large":"","text":"","href":"mailto:mpu-online@stag-service.de?subject=Kontaktanfrage%20von%20der%20Webseite&body=Sehr%20geehrter%20Herr%20Weiland,%0D%0A%0D%0A"}},[_vm._v("E-Mail")])],1)],1)],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VCard,{staticClass:"d-flex flex-column"},[_c(VCardTitle,[_vm._v(" Youtube Video ")]),_c(VSpacer),_c(VCardText,[_c('YoutubeEmbed',{attrs:{"src":'https://www.youtube.com/watch?v=QFxazgHFRU8&'}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }