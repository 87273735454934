<template>
  <v-container>
    <v-card color="primary" flat height="100%">
      <v-card-title style="word-break: break-word">
        <h2 style="font-size: 39px; font-weight: normal" class="mb-4">{{ advantages.title }}</h2>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout align-center justify-center row fill-height>
            <v-expansion-panels multiple>
              <v-flex xs12 sm12 md6 lg3 v-for="(subject, index) in advantages.bullets" v-bind:key="subject.title" >
                <v-expansion-panel >
                  <v-expansion-panel-header :style="$vuetify.breakpoint.mdAndUp ? 'height: 120px' : $vuetify.breakpoint.smAndDown ? '': 'height: 80px'">
                    <span style="font-size: 19px; word-break: break-word;" v-html="subject.title" />
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div :id="'advantage' + index" >
                      <span v-html="subject.body" />
                      <v-spacer></v-spacer>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-flex>
            </v-expansion-panels>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Advantages",
  data: () => ({
    largestPanel: 0
  }),
  methods: {
  },
  computed: {
    advantages() {
      return this.$t("Landing.advantages");
    }
  },
}
</script>

<style scoped>

</style>
