<template>
  <v-container class="mt-13">
    <BlogLayout />
  </v-container>
</template>

<script>
import BlogLayout from "../components/BlogLayout";

export default {
  name: "Interview",
  metaInfo: {
    title: 'Blog - MPU Vorbereitungskurs Online',
  },
  data: () => ({}),
  components: {
    BlogLayout,
  },
  methods: {},
}
</script>

<style scoped>

</style>
