import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"flat":"","color":"primary"}},[_c(VCardTitle,{staticStyle:{"word-break":"break-word"}},[_c('h1',{staticClass:"mb-4",staticStyle:{"font-size":"39px","font-weight":"normal"}},[_vm._v(_vm._s(_vm.interview.title))])]),_c(VCardSubtitle,{staticStyle:{"word-break":"break-word"},domProps:{"innerHTML":_vm._s(_vm.interview.preview)}}),_c(VCardText,_vm._l((_vm.interview.text),function(bullet){return _c(VTimeline,{key:bullet.id,attrs:{"reverse":"","dense":_vm.$vuetify.breakpoint.smAndDown}},[_c(VTimelineItem,{attrs:{"large":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VAvatar,[_c('img',{attrs:{"alt":"MPU Vorbereitungskurs Online - Stefan J. Weiland","src":require("../assets/AvatarStef.jpeg")}})])]},proxy:true}],null,true)},[_c(VCard,{staticClass:"elevation-2"},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.interview.persons[0])+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(bullet.question)+" ")])],1)],1),_c(VTimelineItem,{attrs:{"large":"","color":"primary"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VAvatar,[_c('img',{attrs:{"alt":"MPU Vorbereitungskurs Online - Rechtsanwalt Tobias Jung","src":require("../assets/AvatarJung.png")}})])]},proxy:true}],null,true)},[_c(VCard,{staticClass:"elevation-2"},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.interview.persons[1])+" ")]),_c(VCardText,{domProps:{"innerHTML":_vm._s(bullet.answer)}})],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }