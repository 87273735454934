<template>
  <v-container class="mt-13">
    <v-container>
      <v-card>
        <v-card-title>
          Impressum
        </v-card-title>
        <v-card-text>
          <ul style="list-style: none">
            <li>STAG Seminar und Coaching UG (haftungsbeschränkt)</li>
            <li>Geschäftsführer Stefan Weiland</li>
            <li>Frohsinnstraße 9</li>
            <li>63739 Aschaffenburg</li>
            <li>Deutschland</li>
            <li>Amtsgericht Aschaffenburg HRB Nr. 17237</li>
          </ul>
          <br/>
          <ul style="list-style: none">
            <li><b>E-Mail:</b> info@stagrecht.de</li>
            <li><b>Telefon:</b> +49 6021 3627988</li>
            <li><b>USt-ID:</b> DE 323 38 37 55</li>
          </ul>
          <br/>
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>
<script>
export default {
  name: "Impressum",
  metaInfo: {
    title: 'Impressum - MPU Vorbereitungskurs Online',
  },
  data: () => ({
  }),
  methods: {
  }
}
</script>

<style scoped>

</style>
