<template>
  <v-container>
  <v-card color="primary">
    <v-card-title>
      Unser Team
    </v-card-title>
    <v-card-text>
      <v-layout
          justify-center
          style="margin: 0 12vw 0 12vw"
          text-center
          wrap>
        <v-flex v-for="person in team" :key="person.image" sm6 xs12>
          <v-avatar
              size=150>
            <img :alt="'MPU Vorbereitungskurs Online - ' + person.name" :src="require('../assets/' + person.image)">
          </v-avatar>
          <p style="margin-bottom: 0; margin-top: 8px"><b v-text="person.name"/></p>
          <v-btn v-if="person.phone" color="accent" :href="'tel:' + person.phone" icon style="padding: 0">
            <v-icon size="16">mdi-phone-classic</v-icon>
          </v-btn>
          <p v-text="person.position"/>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Team",
  data: () => ({
    team: [
      {image: "AvatarStef.jpeg", name: "Stefan J. Weiland", phone: "+49 6021 3627988", position: "Geschäftsführer"},
      {image: "AvatarCastillo.png", name: "Michael Castillo", phone: "", position: "Facharzt für Arbeits- und Betriebsmedizin"},
      {image: "AvatarJung.png", name: "Rechtsanwalt Tobias Jung", phone: "", position: ""},
      {image: "AvatarGoetz.jpg", name: "Götz W. Gleichmann", phone: "", position: "Geschäftsführer"},
    ]
  }),
}
</script>

<style scoped>

</style>
