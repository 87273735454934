import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"color":"primary"}},[_c(VCardTitle,[_vm._v(" Unser Team ")]),_c(VCardText,[_c(VLayout,{staticStyle:{"margin":"0 12vw 0 12vw"},attrs:{"justify-center":"","text-center":"","wrap":""}},_vm._l((_vm.team),function(person){return _c(VFlex,{key:person.image,attrs:{"sm6":"","xs12":""}},[_c(VAvatar,{attrs:{"size":"150"}},[_c('img',{attrs:{"alt":'MPU Vorbereitungskurs Online - ' + person.name,"src":require('../assets/' + person.image)}})]),_c('p',{staticStyle:{"margin-bottom":"0","margin-top":"8px"}},[_c('b',{domProps:{"textContent":_vm._s(person.name)}})]),(person.phone)?_c(VBtn,{staticStyle:{"padding":"0"},attrs:{"color":"accent","href":'tel:' + person.phone,"icon":""}},[_c(VIcon,{attrs:{"size":"16"}},[_vm._v("mdi-phone-classic")])],1):_vm._e(),_c('p',{domProps:{"textContent":_vm._s(person.position)}})],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }