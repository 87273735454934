<template>
    <v-container class="mt-13">
      <v-container>
        <v-card>
          <v-card-title>
            Bestimmungen zum Datenschutz/Private Policy
          </v-card-title>
          <v-card-text>
            Diese Erklärung richtet sich an alle Besucher unserer Webseite, Kunden und Partner und wird immer dann wichtig, wenn wir Daten über Sie erhalten. Dabei spielt es keine Rolle, ob Sie Kunde geworden sind oder überhaupt ein Vertrag zwischen uns besteht. Diese Erklärung soll in jeden Fall für Sie transparent machen, wie wir mit Daten umgehen.
          </v-card-text>
          <v-card-subtitle>
            <h3>1. Verantwortliche Stelle</h3>
          </v-card-subtitle>
          <v-card-text>
            Verantwortliche Stelle im rechtlichen Sinne für das Thema Daten ist das auf unserer Webseite unter „Impressum“ genannte Unternehmen. Sie können sich an die dort genannte Geschäftsführung: <br />
            STAG Dienstleistungsgesellschaft mbH<br />
            Frohsinnstr. 30<br />
            63739 Aschaffenburg<br />
            Sie erreichen uns auch per Email: info@stagrecht.de<br />
          </v-card-text>
          <v-card-subtitle>
            <h3>2. Verantwortlich für journalistisch-redaktionelle Inhalte nach § 18 MStV</h3>
          </v-card-subtitle>
          <v-card-text>
            STAG Dienstleistungsgesellschaft mbH<br />
            vertreten durch den Geschäftsführer<br />
            Stefan J. Weiland<br />
            Frohsinnstr. 30<br />
            63739 Aschaffenburg<br />
          </v-card-text>
          <v-card-subtitle>
            <h3>3. Zweck der Speicherung</h3>
          </v-card-subtitle>
          <v-card-text>
            Wenn Sie einen Vertrag mit uns eingehen, legen wir in unserem System ein Kundenkonto an. Darin sind die von Ihnen angegeben Stammdaten, Ihre Telefonnummer, E-Mailadresse und Ihre Bestell- und ggfls. Ihre Abrechnungsdaten, ebenso Ihre Angaben, die für die Geltendmachung und Verteidigung Ihrer Rechte im Rahmen des Mandats erforderlich sind, enthalten („Kundendaten“).<br />
            Ihr Computer übermittelt uns Ihre IP-Adresse wenn sie unsere Webseiten benutzen
            und abhängig von der Art der Nutzung und von den Einstellungen Ihres Computers
            speichern wir dabei kleine Textdateien auf Ihrer Festplatte („Cookies“). Wir legen
            diese Dateien an, damit unsere Webseite für Sie besser funktioniert. Es ist eine Art
            Kurzzeitgedächtnis ihres Browsers. <br />
            Hierdurch gewährleisten wir einen reibungslosen Verbindungsaufbaus der Website, gewährleisten eine komfortablen Nutzung unserer Website, die Auswertung erfolgt im Rahmen der Systemsicherheit und -stabilität sowie zu weiteren administrativen Zwecken. Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen. Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein. Nähere Erläuterungen dazu erhalten Sie unter den Ziff. 4 und 5 dieser Datenschutzerklärung.
            <br />
            Wir speichern und verarbeiten diese Daten, weil wir sonst den Vertrag mit Ihnen nicht erfüllen könnten.
            <br /><br />
            Ihr Computer übermittelt uns Ihre IP-Adresse wenn sie unsere Webseiten benutzen und abhängig von der Art der Nutzung und von den Einstellungen Ihres Computers speichern wir dabei kleine Textdateien auf Ihrer Festplatte („Cookies“). Wir legen diese Dateien an, damit unsere Webseite für Sie besser funktioniert. Es ist eine Art Kurzzeitgedächtnis ihres Browsers. Wir legen außerdem auf unserem System Textdateien an, die folgende Informationen über Sie enthalten können: Browsertyp und Browserversion, verwendetes Betriebssystem, die URL der Webseite von der Sie kommen, der Name Ihres Computers und die Uhrzeit („Logfiles“). Die Logfiles sind für uns nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit Cookies oder IP Adresse nehmen wir nicht vor. Wir behalten uns aber vor, diese Daten nachträglich in Einzelfällen auszuwerten, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung vorliegen. Die Logfiles helfen uns zu verstehen, auf welche Art von Computern unsere Webseiten funktionieren müssen und wann besonders viele (oder wenige) Personen unsere Webseiten nutzen. Auf diese Art und Weise können Webseitenstruktur, Server und Datenbanksysteme darauf eingestellt werden.
          </v-card-text>
          <v-card-subtitle>
            <h3>4. Weitergabe der Daten an Dritte</h3>
          </v-card-subtitle>
          <v-card-text>
            Wir verarbeiten Ihre Daten nicht vollständig selbst sondern setzen dabei Programme und Services von anderen Firmen ein („Tools“). Die verwendeten Tools werden wir von Zeit zu Zeit ändern, wenn es uns aus rechtlichen, technischen oder wirtschaftlichen Erwägungen heraus sinnvoll erscheint. 	Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.
            <br />
            <br />
            Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
            <ul>
              <li>Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,</li>
              <li>die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung,  Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,</li>
              <li>für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO  eine gesetzliche Verpflichtung besteht, sowie</li>
              <li>dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die  Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.</li>
            </ul>
            <ul>
              <li>Google Analytics (Besuchermessung auf der Webseite)</li>
              <li>Google Tag Manager (Installation der Tracking Events für die</li>
              <li>Besuchermessung)</li>
              <li>Strato Webhosting (Webseitenbetrieb, Serverplatz</li>
              <li>Strato Backup</li>
              <li>Strato Webmail (Kontaktdaten, Emails)</li>
              <li>PayPal (Abwicklung von Bezahlungen)</li>
              <li>Keycloak (Nutzerverwaltung)</li>
              <li>SpingBoot (Admin)</li>
              <li>SpringBoot (Nutzerverwaltung)</li>
              <li>Prometheus (Request logging)</li>
              <li>Grafana (Veranschaulichungstool für Prometheus)</li>
              <li>Alert Manager (Benachrichtigungsservice)</li>
              <li>NginX (IP Adressen)</li>
              <li>LetsEncrypt (Zertifikatsmanager)</li>
              <li>Facebook Pixel</li>
            </ul>
            <h5>YouTube</h5>
            Wir setzen auf unseren Internetseiten (Videos) des Videoportals „YouTube“ des Unternehmens Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (Im Folgenden: „Google“) ein. Die Implementierung erfolgt aufgrund von Art. 6 Abs. 1 S. 1 lit. f DSGVO, wobei unser Interesse in der reibungslosen Integration der Videos und der damit ansprechenden Gestaltung unserer Webseite liegt. Hierbei nutzen wir die von Google zur Verfügung gestellte Option des »erweiterter Datenschutzmodus«. Wenn Sie eine Seite aufrufen, die über ein eingebettetes Video verfügt, wird eine Verbindung zu den Google-Servern hergestellt und dabei der Inhalt durch Mitteilung an Ihren Browser auf der Internetseite dargestellt. Laut den Angaben von Google werden im »erweiterten Datenschutzmodus« nur dann Ihre Daten – insbesondere welche unserer Internetseiten Sie besucht haben sowie gerätespezifische Informationen inklusive der IP-Adresse – an den YouTube-Server in den USA übermittelt, wenn Sie das Video anschauen. Durch den Klick auf das Video willigen Sie in diese Übermittlung ein. Sind Sie gleichzeitig bei Google eingeloggt, werden diese Informationen Ihrem Mitgliedskonto bei YouTube zugeordnet. Dies können Sie verhindern, indem Sie sich vor dem Besuch unserer Website von Ihrem Mitgliedskonto abmelden. Teilweise werden Informationen an die Muttergesellschaft Google Inc. mit Sitz in den USA, an andere Google- Unternehmen und an externe Partner von Google übermittelt, die sich jeweils außerhalb der Europäischen Union befinden können. Google verwendet dafür von der Europäischen Kommission genehmigte Standardvertragsklauseln und verlässt sich auf die von der Europäischen Kommission erlassenen Angemessenheitsbeschlüsse bezüglich bestimmter Länder. Weitere Informationen zum Datenschutz im Zusammenhang mit YouTube finden Sie in den <a href="https://policies.google.com/privacy?hl=de&gl=de">Datenschutzbestimmungen von Google</a>.
            <br />
            Wenn Sie Ihre Zustimmung zur Verwendung dieser Tools untersagen, können wir möglicherweise zwischen uns bestehende Verträge nicht mehr erfüllen oder müssen auf Lösungen ausweichen, die für Sie weniger komfortabel sind.
          </v-card-text>
          <v-card-subtitle>
            <h3>5. Löschfristen</h3>
          </v-card-subtitle>
          <v-card-text>
            Wir bewahren ihre Daten bis zur Erledigung des Auftrages auf oder bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind. Diese ergeben sich vor allem aus § 257 HGB, wo die Aufbewahrung von geschäftlichen Unterlagen geregelt ist.
          </v-card-text>
          <v-card-subtitle>
            <h3>6. Auskunft</h3>
          </v-card-subtitle>
          <v-card-text>
            Sie haben jederzeit das gesetzliche Recht, Auskunft über die von uns gespeicherten Daten zu verlangen. Sollten trotz unserer Bemühungen um Datenrichtigkeit und Aktualität falsche Informationen über Sie gespeichert sein, werden wir diese auf Ihre Aufforderung hin so schnell wie möglich berichtigen. Beruht eine Verarbeitung auf Ihre Einwilligung, können sie diese jederzeit für die Zukunft widerrufen.
          </v-card-text>
          <v-card-subtitle>
            <h3>7. Aufsichtsbehörde</h3>
          </v-card-subtitle>
          <v-card-text>
            Sollten Sie das Gefühl haben, dass wir unseren Informationspflichten Ihnen gegenüber nicht nachkommen, haben Sie das Recht, sich bei 	der zuständigen Aufsichtsbehörde zu beschweren. <br /> <br />
            Datenschutzbeauftragte/r des Landes Bayern, Postfach 22 12 19, 80502 München
            Besucheranschrift: Wagmüllerstraße 18, 80538 München, Telefon: 089 212672-0,
            Fax: 089 212672-50, Mail poststelle@datenschutz-bayern.de.
          </v-card-text>
          <v-card-subtitle>
            <h3>8. Freiwilligkeit</h3>
          </v-card-subtitle>
          <v-card-text>
            Zur Übermittlung ihrer Daten an uns sind sie nicht verpflichtet. Die Speicherung Ihrer IP Adresse können Sie daher ablehnen. Für den Abschluss und die Durchführung von Verträgen zwischen Ihnen und uns ist die Verarbeitung Ihrer Daten im oben beschriebenen Umfang allerdings notwendig. Widerrufen Sie Ihre Einwilligung zur Datenverarbeitung, wird unsere Leistung an Sie unmöglich, ohne dass Sie von Ihrer Leistungspflicht frei werden. (Die Speicherung Ihrer Kundendaten in den oben genannten Verwaltungssystemen können Sie aus diesem Grund bis zur Beendigung des Vertragsverhältnisses nicht widerrufen).
          </v-card-text>
          <v-card-subtitle>
            <h3>9. Zweckänderung
            </h3>
          </v-card-subtitle>
          <v-card-text>
            Sollten wir beabsichtigen, ihre Daten für einen anderen Zweck weiterzuverarbeiten als den, für den sie sie uns gegeben haben, so stellen wir ihnen vor dieser Weiterverarbeitung konkrete Informationen über diesen anderen Zweck und den Grund für unser Vorhaben zur Verfügung. Sie können die Einwilligung zur weiteren Verarbeitung jederzeit widerrufen.
          </v-card-text>
          <v-card-subtitle>
            <h4>Aschaffenburg, den 15.02.2022</h4>
          </v-card-subtitle>
        </v-card>
      </v-container>
    </v-container>
</template>


<script>
export default {
  name: "Datenschutz",
  metaInfo: {
    title: 'Datenschutz - MPU Vorbereitungskurs Online',
  },
  data: () => ({
  }),
  methods: {
  }
}
</script>

<style scoped>

</style>
