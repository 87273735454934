<template>
  <v-container>
    <v-card flat color="transparent">
      <v-card-title style="word-break: break-word">
        <h2 style="font-size: 39px; font-weight: normal" class="mb-4"> {{ BlogPreview.title }} </h2>
      </v-card-title>
      <v-card-text>
        <v-row>
    <v-col cols="12" lg="4" v-for="news in BlogPreview.news" :key="news.id">
      <v-card color="primary" v-if="news.type === 'iv'">
        <v-card-title> {{ news.title }} </v-card-title>
        <v-card-text>

                <v-card>
                  <v-card-text>
                    <div v-html="news.preview"></div>
                  </v-card-text>
                </v-card>
                <div class="mt-7 ml-3 mr-3">
                <v-row >
                  <v-card class="elevation-2">
                    <v-list-item class="mt-2">
                      <v-list-item-content>
                        <v-list-item-title class="text-h5" style="word-break: break-word">
                          {{ news.persons[0] }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-avatar
                          size="50"
                      >
                        <img alt="MPU Vorbereitungskurs Online - Stefan J. Weiland" src="../assets/AvatarStef.jpeg">
                      </v-list-item-avatar>
                    </v-list-item>
                    <v-card-text>
                      {{ news.text[0].question }}
                    </v-card-text>
                  </v-card>
                </v-row>
                <v-row  class="mt-4">
                  <v-card class="elevation-2">
                    <v-list-item class="mt-2">
                      <v-list-item-content>
                        <v-list-item-title class="text-h5" style="word-break: break-word">
                          {{ news.persons[1] }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-avatar
                          size="50"
                      >
                        <img alt="MPU Vorbereitungskurs Online - Rechtsanwalt Tobias Jung" src="../assets/AvatarJung.png">
                      </v-list-item-avatar>
                    </v-list-item>
                    <v-card-text v-html="news.text[0].answer" />
                  </v-card>
                </v-row>
                </div>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-dialog>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs"
                     v-on="on"> Zum Interview </v-btn>
            </template>
            <template v-slot:default="dialog">
              <Blog v-bind:News="news" v-bind:dialog="dialog" />
            </template>
          </v-dialog>
        </v-card-actions>
      </v-card>
      <v-card color="primary" v-if="news.type === 'blog'">
        <v-card-title> {{ news.title }} </v-card-title>
        <v-card-subtitle> {{ news.subtitle }} </v-card-subtitle>
        <v-card-subtitle> {{ news.date }} </v-card-subtitle>
        <v-card-text>
          <v-card>
            <v-card-text>
              <div v-for="index in 1" v-bind:key="news.text[index-1].title[0]">
                <h4 class="text-h5" v-html="news.text[index-1].title"></h4>
                <p class="text-body-1" v-html="(news.text[index-1].body.split('.')[0] + '.') + (news.text[index-1].second ? news.text[index-1].body.split('.')[1] + '.' : '')"></p>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-dialog>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs"
                       v-on="on"> Zum Blogbeitrag </v-btn>
              </template>
            <template v-slot:default="dialog">
            <Blog v-bind:News="news" v-bind:dialog="dialog" />
            </template>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Blog from "./Blog";

export default {
  name: "BlogPreview",
  components: { Blog },
  data: () => ({}),
  methods: {},
  computed: {
    BlogPreview() {
      return this.$t("News");
    }
  }
}
</script>

<style scoped>

</style>
