<template>
  <v-container class="mt-13">
    <v-container>
      <v-card>
        <v-card-title>
          Widerrufsbelehrung
        </v-card-title>
        <v-card-text>
          <v-list dense>
            <v-list-item>
              1. Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
            </v-list-item>
            <v-list-item>
              <span>2.	Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses (§ 355 Abs. 2 BGB). Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (STAG Dienstleistungsgesellschaft mbH, Frohsinnstraße 30, 63739 Aschaffenburg, info@stagrecht.de) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-<strong>Widerrufsformula</strong> verwenden, das jedoch nicht vorgeschrieben ist.
         </span>
                 </v-list-item>
            <v-list-item>
              3.	Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden. Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrages unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
            </v-list-item>

          </v-list>

          <h2 style="margin-top: 50px">Folgen des Widerrufs</h2>
          Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.


          <v-card color="error" style="margin: 20px 10% 50px 10%">
            <v-card-title>Erlöschen des Widerrufsrechts / Widerrufsverzicht</v-card-title>
            <v-card-text>Ich verlange ausdrücklich und stimme gleichzeitig zu, dass Sie mit der in Auftrag gegebenen Dienstleistung vor Ablauf der Widerrufsfrist beginnen. Ich weiß, dass mein Widerrufsrecht bei Erfüllung des Vertrages erlischt.</v-card-text>

          </v-card>
          Diese <strong>ausdrückliche Zustimmung</strong> des Verbrauchers kann 	nicht  durch
          entsprechende Regelungen in den AGB fingiert werden. Für die ausdrückliche
          Zustimmung des Verbrauchers als auch für die Kenntnisnahme über den Verlust des
          Widerrufsrechts ist der Online-Händler <strong>beweisbelastet</strong>.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text href="docs/Widerrufsformular.pdf" target="_blank">Widerrufsformular download</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "Widerrufsrecht",
  metaInfo: {
    title: 'Widerrufsrecht - MPU Vorbereitungskurs Online',
  },
}
</script>

<style scoped>

</style>
