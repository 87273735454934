<template>
    <v-footer
            style="background-image: linear-gradient(131deg, rgba(170,200,224,1) 0%, rgba(170,181,224,0.989233193277311) 50%, rgba(182,170,224,1) 100%)"
            padless

    >
        <v-layout
                justify-center
                wrap
        >
            <v-btn
                    v-for="link in links"
                    :key="link.id"
                    color="white"
                    text
                    rounded
                    class="my-2"
                    :to=link.ref
            >
                {{ link.title }}
            </v-btn>
            <v-flex
                    style="background-image: linear-gradient(131deg, rgba(170,200,224,1) 0%, rgba(170,181,224,0.989233193277311) 50%, rgba(182,170,224,1) 100%)"
                    py-4
                    text-center
                    white--text
                    xs12
            >
                © {{ new Date().getFullYear() }} — <strong>STAG Dienstleistungsgesellschaft mbH</strong>
            </v-flex>
        </v-layout>
    </v-footer>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        methods: {
        },
        data: () => ({
            links: [
                {
                    title: "Datenschutz",
                    ref: "/datenschutz"
                },
                {
                    title: "Impressum",
                    ref: "/impressum"
                },
              {
                title: "Widerrufsbelehrung",
                ref: "/widerrufsrecht"
              },
              {
                title: "AGBs",
                ref: "/agb"
              }
            ]
        }),
    };
</script>
